import React, {useEffect} from 'react';
import TourTableTheme from "../TourTableTheme";



const Jury = ({tour, teams}) => {


    return (
        <div>
            <div>
                <TourTableTheme tour={tour} teams={teams}/>
            </div>

        </div>
    );
};

export default Jury;