import React, {useEffect, useState} from 'react';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import CreateIcon from '@mui/icons-material/Create';
import Button from "@mui/material/Button";
import {styled, tableCellClasses} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {getAnswers} from "./App";



const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E8E8E8',
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const TourTableTheme =  ({tour, teams}) => {
    const [results, setResults] = useState([])

    const updateResults = () => {
        getAnswers().then(res => {
            const data = res.data
            setResults(data)
            console.log(data, 'data')
        })
            .catch((e) => {
                console.log(e)})

    }

    useEffect(() => {
        getAnswers().then(res => {
            const data = res.data
            setResults(data)
            console.log(data, 'data')
        })
            .catch((e) => {
                console.log(e)})


    }, [])

    return (

        <div style={{margin: "20px"}}>
            <div>
                <Button variant="contained"
                        size="large"
                        style={{margin: "1rem", position: "relative"}}
                        sx={{width: 400}}
                        onClick={updateResults}
                >
                    Обновить результат
                </Button>

            </div>





            <Paper sx={{width: "100%"}}>
                <TableContainer  >
                    <Table  aria-label="customized table">
                        <TableHead>
                            <StyledTableRow >
                                <StyledTableCell align="center" width="5%">Тур №</StyledTableCell>
                                <StyledTableCell align="center" width="5%">Номер вопроса</StyledTableCell>
                                <StyledTableCell align="center" width="7.5%">Авангард</StyledTableCell>
                                <StyledTableCell align="center" width="7.5%">Академики</StyledTableCell>
                                <StyledTableCell align="center" width="7.5%">Граница</StyledTableCell>
                                <StyledTableCell align="center " width="7.5%">Защитники отечества</StyledTableCell>
                                <StyledTableCell align="center" width="7.5%">Меркурий</StyledTableCell>
                                <StyledTableCell align="center" width="7.5%">Нотариальная тайна</StyledTableCell>
                                <StyledTableCell align="center" width="7.5%">ЦПК МВД</StyledTableCell>
                                <StyledTableCell align="center" width="7.5%">Чистосердечное знание</StyledTableCell>
                                <StyledTableCell align="center" width="7.5%">Команда 9</StyledTableCell>
                                <StyledTableCell align="center" width="7.5%">Команда 10</StyledTableCell>
                            </StyledTableRow>

                        </TableHead>
                        <TableBody>
                            {
                                results.map((row) => {
                                    return (
                                        <StyledTableRow key={row.id} >
                                            <StyledTableCell align="center" width="5%">{row.rounds}</StyledTableCell>
                                            <StyledTableCell align="center" width="5%">{row.num}</StyledTableCell>
                                            <StyledTableCell align="center" width="7.5%">{row.comand1}</StyledTableCell>
                                            <StyledTableCell align="center" width="7.5%">{row.comand2}</StyledTableCell>
                                            <StyledTableCell align="center" width="7.5%">{row.comand3}</StyledTableCell>
                                            <StyledTableCell align="center" width="7.5%">{row.comand4}</StyledTableCell>
                                            <StyledTableCell align="center" width="7.5%">{row.comand5}</StyledTableCell>
                                            <StyledTableCell align="center" width="7.5%">{row.comand6}</StyledTableCell>
                                            <StyledTableCell align="center" width="7.5%">{row.comand7}</StyledTableCell>
                                            <StyledTableCell align="center" width="7.5%">{row.comand8}</StyledTableCell>
                                            <StyledTableCell align="center" width="7.5%">{row.comand9}</StyledTableCell>
                                            <StyledTableCell align="center" width="7.5%">{row.comand10}</StyledTableCell>
                                        </StyledTableRow>
                                    )

                                })

                            }
                                </TableBody>
                    </Table>
                </TableContainer>
            </Paper>



        </div>
    );
};

export default TourTableTheme;
