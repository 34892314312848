import React from 'react';
import {Box, Button, Container, CssBaseline, Stack,} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const Menu = () => {
    return (
        <Container component="main" maxWidth="xs"  >
            <Stack alignItems={"center"} >
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        border: "1px solid",
                        borderRadius: "5px"
                    }}>
                    <CssBaseline/>
                    <Stack alignItems={"center"}>
                        <Link
                            style={{ textDecoration: "none", color: "#fff" }}
                            to="/game"
                        >
                        <Button
                            variant="contained"
                            size="large"
                            style={{margin: "1rem", position: "relative"}}
                            sx={{width: 400}}
                    >

                            Игра Брейринг

                    </Button></Link></Stack>
                    <Stack alignItems={"center"}>
                        <Link
                        style={{ textDecoration: "none", color: "#fff" }}
                        to="/jury"
                    >
                        <Button
                            variant="contained"
                            size="large"
                            style={{margin: "1rem", position: "relative"}}
                            sx={{width: 400}}
                            // style={{
                            //     textDecoration: "none",
                            //     color: "#0068FE",
                            //     borderBottom: "1px solid",
                            // }}
                        >

                                Жюри

                        </Button>
                    </Link>
                    </Stack>


                </Box>
            </Stack>



        </Container>
    );
};

export default Menu;