import React, {useState} from 'react';
import {Autocomplete, Box, Button, Dialog, DialogTitle, Modal, Stack, TextField} from "@mui/material";
import {getAnswer} from "../App";


const styleBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    // maxHeight: 700,
    // overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const Game = ({tour, teams}) => {

    const [answerValue, setAnswerValue] = useState('')
    const [numberQuestionValue, setNumberQuestionValue] = useState("")
    const [selectedTeamValue, setSelectedTeamValue] = useState({})
    const [selectedTourValue, setSelectedTourValue] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [title, setTitle] = useState("");


    const onNumberValueChange = (e) => {
        if (Number(e.target.value) > 7) {
            setNumberQuestionValue(7);
        } else if (Number(e.target.value) < 1) {
            setNumberQuestionValue(1);
        }else {
            setNumberQuestionValue(e.currentTarget.value)
        }

    }

    const onAnswerValueChange = (e) => {
        setAnswerValue(e.currentTarget.value)
    }

    const handleClose = () => {
        setOpenModal(false);
    };


    const onSubmit = () => {
        const obj =
            {
                "name": selectedTeamValue?.name,
                "rounds": Number(selectedTourValue?.label),
                "num": Number(numberQuestionValue),
                "answer": answerValue
            }
        getAnswer(obj).then(res => {
            const data = res.data
            setOpenModal(true)
            setTitle("Ваш ответ успешно отправлен!")
            setNumberQuestionValue("")
            setSelectedTourValue(null)
            setAnswerValue("")

        })
            .catch((e) => {
                setOpenModal(true)
                setTitle("Ваш ответ не отправлен. Заполните все поля для отправки ответа!")
                console.log(e)})

    }
    return (
        <div className="container">

            <Stack alignItems={"center"}>
                <Box>

                    <div><h2 style={{maxWidth: 400}}>Интеллектуальный конкурс между государственными органами, посвященный Дню Конституции в 2024 году</h2></div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Stack sx={{padding: 2}}>
                            <h4>Команда</h4>
                            <Autocomplete
                                id="nameTeam"
                                value={selectedTeamValue}
                                onChange={(event, newValue) => {
                                    setSelectedTeamValue(newValue)
                                }}
                                // autoSelect
                                options={teams}
                                renderOption={(props, option,) => {
                                   return ( <li {...props} key={option.id}>
                                        {option.label || ""}
                                    </li>)
                                }}
                                renderInput={(params) => {
                                    return(<TextField {...params} label="Название команды"/>)}
                                }

                                sx={{minWidth: 300}}
                                getOptionLabel={option => option?.label || ""}
                            />


                        </Stack>
                        <Stack sx={{padding: 2}}>
                            <h4>Номер тура</h4>
                            <Autocomplete
                                value={selectedTourValue}
                                onChange={(event, newTour) => {
                                    setSelectedTourValue(newTour)
                                }}
                                options={tour}
                                renderInput={(params) => <TextField {...params} label="Номер тура"/>}
                                sx={{minWidth: 300}}
                                getOptionLabel={option => option?.label || null}
                            />

                        </Stack>
                        <Stack sx={{padding: 2}}>
                            <h4>Номер вопроса</h4>
                            <TextField id="numberQuestion"
                                       placeholder="Номер вопроса"
                                       type="number"
                                       variant="outlined"
                                       sx={{minWidth: 300}}
                                       value={numberQuestionValue}
                                       InputProps={{ inputProps: { min: "1", max: "7", step: "1" } }}
                                       onChange={onNumberValueChange}
                            />
                        </Stack>
                        <Stack sx={{padding: 2}}>
                            <h4>Ответ на вопрос</h4>
                            <TextField id="answer"
                                       placeholder="Введите ответ"
                                       type="text"
                                       variant="outlined"
                                       sx={{minWidth: 300}}
                                       value={answerValue}
                                       onChange={onAnswerValueChange}
                            />
                        </Stack>
                    </div>

                    <Button
                        variant="contained"
                        size="large"
                        style={{margin: "1rem", position: "relative"}}
                        sx={{minWidth: 300}}
                        onClick={onSubmit}
                    >
                        Готово
                    </Button>

                </Box>
            </Stack>
            <Dialog open={openModal} onClose={handleClose}>
                <DialogTitle >
                    {title}
                </DialogTitle>
                    <Button
                        variant="contained"
                        size="large"
                        style={{margin: "2rem", position: "relative",}}
                        onClick={handleClose} autoFocus
                    >
                       Ок
                    </Button>
            </Dialog>


        </div>


    );
};

export default Game;