import * as React from 'react';
import './App.css';
import TourTableTheme from "./TourTableTheme";

import {Autocomplete, Box, Button, createTheme, Stack, TextField, ThemeProvider} from "@mui/material";

import {useState} from "react";

import axios from "axios";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Menu from "./Menu/Menu";
import Game from "./Game/Game";
import Jury from "./Jury/Jury";

const teams = [
    {id:1, name: 'Команда 1', label: 'Авангард'},
    {id:2, name: 'Команда 2', label: 'Академики'},
    {id:3, name: 'Команда 3', label: 'Граница'},
    {id:4, name: 'Команда 4', label: 'Защитники отечества'},
    {id:5, name: 'Команда 5', label: 'Меркурий'},
    {id:6, name: 'Команда 6', label: ' Нотариальная тайна'},
    {id:7, name: 'Команда 7', label: 'ЦПК МВД'},
    {id:8, name: 'Команда 8', label: 'Чистосердечное знание'},
    {id:9, name: 'Команда 9', label: 'Команда 9'},
    {id:10, name: 'Команда 10', label: 'Команда 10'},
]
const tour = [
    {label: 1,},
    {label: 2},
    {label: 3},
    {label: 4},
    {label: 5},

    // { label: 'Тур 1', },
    // {label: 'Тур 2'},
    // { label: 'Тур 3' },
    // { label: 'Тур 4' },
    // { label: 'Тур 5' },

]

export const getAnswer = (body) => {

    // return axios.post('http://192.168.109.49:80/dream_teams/', body, {})
     return axios.post('http://brain-ring.asterus.by/dream_teams/', body, {})
}

export const getAnswers = () => {

    // return axios.get('http://192.168.109.49:80/dream_teams/')
    return axios.get('http://brain-ring.asterus.by/dream_teams/')
}


export default function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: "#C2D3F1",
            },
            secondary: {
                main: "#197b69",
            },
        },
    });

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Menu />} />
                    <Route path="game" element={<Game teams={teams} tour={tour}/>} />
                    <Route path="jury" element={<Jury teams={teams} tour={tour} />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}




